import SimpleSlider from './components/simpleSlider';
import AccommodationSlider from './components/accommodationSlider';
import HeroSlider from './components/heroSlider';
import BlogSlider from './components/blogSlider';
import GallerySlider from "./components/gallery-slider";
// import AccommodationSlider from './components/accommodationSlider';

export default function initSliders() {
    // Simple Sliders
    const simpleSliders = document.querySelectorAll('.js-slider');
    if (simpleSliders) {
        for (let i = 0; i < simpleSliders.length; i++) {
            const slider = new SimpleSlider(simpleSliders[i]);
            slider.init();
            // console.log(slider);
            // console.log(slider.sliderOptions);
        }
    }
    // Accommodation Slider
    const accommodationSlider = document.querySelectorAll('.js-accommodation-slider');
    if (accommodationSlider) {
      for (let i = 0; i < accommodationSlider.length; i++) {
        let acSlider = new AccommodationSlider(accommodationSlider[i]);
        acSlider.init();
      }
    }

    // Hero Slider
    const heroSlider = document.querySelectorAll('.js-hero-slider');
    if (heroSlider) {
      for (let i = 0; i < heroSlider.length; i++) {
        let hSlider = new HeroSlider(heroSlider[i]);
        hSlider.init();
      }
    }

    // blog Slider
    const blogSlider = document.querySelectorAll('.js-blog-slider');
    if (blogSlider) {
      for (let i = 0; i < blogSlider.length; i++) {
        let bSlider = new BlogSlider(blogSlider[i]);
        bSlider.init();
      }
    }

    // gallery slider
    const galSliders = document.querySelectorAll('.js-galleries-slider');
    if (galSliders) {
      for (let i = 0; i < galSliders.length; i++) {
        let gSlider = new GallerySlider(galSliders[i]);
        gSlider.init();
      }
    }


    // function gallerieSlidersInit(){
    //     const galSliders = document.querySelectorAll('.js-galleries-slider');
    //     if (galSliders){
    //       for (var i = 0; i < galSliders.length; i++) {
    //         // GalleriesSlider(galSliders[i]);
    //         let gSlider = new GalleriesSlider(galSliders[i]);
    //         gSlider.init();
    //       }
    //     }
    // }
}
