/* global google, gm */

export default class locationMap {
    constructor(mapElement, center, zoom, marker, content) {
        this.mapElement = mapElement;
        this.center = center.split(',', 2);
        this.zoom = zoom;
        this.mapOptions = {
            center: new google.maps.LatLng(this.center[0], this.center[1]),
            zoom: parseInt(this.zoom),
            scrollwheel: false,
            disableDefaultUI: true,
            draggable: true,
            panControl: true,
            panControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            mapTypeControl: false,
            streetViewControl: false,
            scaleControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.RIGHT_TOP
            }
        };
        if (gm.mapStyles.length) {
            this.mapOptions.styles = JSON.parse(gm.mapStyles);
        }
        this.markerCoords = marker.split(',', 2);
        if (content) {
            this.content = content;
        }
        this.init();
    }
    init() {
        this.map = new google.maps.Map(this.mapElement, this.mapOptions);
        this.setMarkerOptions();
        this.setMarker();
        if (this.content) {
            this.setInfoWindow();
            this.addInfoWindowListeners();
        }
        this.centerMapOnWindowResize();
    }
    setMarkerOptions() {
        const markerURL = gm.mapMarker;
        this.markerOptions = {
            image: markerURL,
            myLatLng: new google.maps.LatLng(this.markerCoords[0], this.markerCoords[1])
        };
    }
    setMarker() {
        this.marker = new google.maps.Marker({
            position: this.markerOptions.myLatLng,
            map: this.map,
            icon: this.markerOptions.image
        });
    }
    setInfoWindow() {
        const infoWindowContent = '<div class="infowindow_wrapper">' + this.content + '</div>';
        this.infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
        });
    }
    addInfoWindowListeners() {
        const openInfoWindow = () => {
            this.infoWindow.open(this.map, this.marker);
        };
        this.marker.addListener('click', openInfoWindow);
    }
    centerMapOnWindowResize() {
        this.map.addListener('bounds_changed', () => {
            window.setTimeout(() => {
                this.map.panTo(this.mapOptions.center);
            }, 1000);
        });
    }
}
