export default function hoverDoesMove() {
  let container = document.querySelectorAll('.background-move');
  // let ary = Array.prototype.slice.call(document.querySelectorAll(".js-full-parallax-cont"));
  let img = document.querySelector('.js-parallax-cont');
  let bgImg = document.querySelectorAll('.js-parallax-item');

  container.forEach((cont, j) => {
      cont.addEventListener('mousemove', parallax);
  });
  function parallax(e){
      if (this.classList.contains('js-do-animation')) {
        this.querySelectorAll('.background-move__img-layer').forEach((layer, i) => {
            const speed = layer.getAttribute('data-speed');
            // const x = (window.innerWidth - e.pageX*speed)/100;
            const x = (window.innerWidth + e.clientX*speed)/150;
            // const y = (window.innerHeight - e.pageY*speed)/100;
            const y = (window.innerHeight + e.clientY*speed)/150;
            layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
        this.querySelectorAll('.background-move__bg-layer').forEach((layer, i) => {
            const speed = layer.getAttribute('data-speed');
            const x = (window.innerWidth - e.pageX*speed)/100;
            layer.style.backgroundPosition = `${x}% 50%`;
        });
      }
  }

}
