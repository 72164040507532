import Swiper from 'swiper';
import SwiperCore, { Navigation, EffectFade, Pagination, Autoplay } from 'swiper/js/swiper.esm.js';

// Install modules
Swiper.use([Navigation, EffectFade, Autoplay]);

export default class SimpleSlider {
    constructor (el) {
        this.slider = el;
        this.sliderOptions = {
            speed: 1200,
            // simulateTouch: false,
            // autoplay: {
            //     delay: 4000,
            // },
            // loop: true,
            loop: false,
            // followFinger: false,
            // watchOverflow: true,
            // effect: 'fade',
            // fadeEffect: {
            //     crossFade: true,
            // },
            // navigation: {
            //     nextEl: '.js-slider-next',
            //     prevEl: '.js-slider-prev',
            // },
            // slidesPerView: 3,
            spaceBetween: 30,
            slidesPerView: 'auto',
            centeredSlides: true,
            grabCursor: true,
            autoHeight: false,
            pagination: {
              el: document.querySelector('.swiper-pagination'),
              clickable: true,
              type: 'fraction',
            },
        };
    }
    init () {
        if (!this.slider || !this.sliderOptions) {
            return;
        }
        new Swiper(this.slider, this.sliderOptions);
    }
}
