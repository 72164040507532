export default function initParallaxElements() {

  let container = document.querySelector('.js-parallax');
  let innerContainer = document.querySelector('.js-parallax-cont');
  let target = document.querySelectorAll('.js-parallax-item');


  if (container && target.length >= 1) {
    let scrolled = window.pageYOffset / 2;
    scrolled = container.pageYOffset;
    scrolled = window.pageYOffset - container.offsetTop;

    let rate = scrolled * 0.5;
    for (var i = 0; i < target.length; i++) {
      let pos = scrolled * target[i].dataset.rate;
      target[i].style.transform = 'translate3d(0px,' + (pos) + 'px, 0px)';
    }
    // target.style.transform = 'translate3d(0px,' + rate + 'px, 0px)';
    let contPos = scrolled * innerContainer.dataset.rate;
    innerContainer.style.transform = 'translate3d(0px,' + (contPos) + 'px, 0px)';

    window.addEventListener('scroll', function(){
      scrolled = window.pageYOffset - container.offsetTop;

      rate = scrolled * 0.5;
      for (var i = 0; i < target.length; i++) {
        let pos = scrolled * target[i].dataset.rate;
        target[i].style.transform = 'translate3d(0px,' + (pos) + 'px, 0px)';
      }
      // target.style.transform = 'translate3d(0px,' + rate + 'px, 0px)';

    });
  }

}
