export default function readMore() {
    const moreCont = document.querySelectorAll('.js-readmore-cont');

    if (moreCont.length >= 1) {
        for (let i = 0; i < moreCont.length; i++) {
          const morePars = moreCont[i].querySelectorAll('.js-hidden-par');
          if(morePars.length >= 1){
            const lessText = moreCont[i].querySelector('.readless-text');
            const moreText = moreCont[i].querySelector('.readmore-text');
            // console.log(moreCont[i]);
            const moreButton = moreCont[i].querySelector('.js-readmore-btn');
            moreButton.addEventListener('click', function(e){
              for (var j = 0; j < morePars.length; j++) {
                morePars[j].classList.toggle('js-visible');
              }
              moreText.classList.toggle('hidden');
              lessText.classList.toggle('hidden');
            });
          }

        }
    }
}
