// import FollowCursor from './FollowCursor';
// import {debounce} from '../helpers';
import Swiper from 'swiper';
import SwiperCore, { Navigation, EffectFade, Pagination, Autoplay } from 'swiper/js/swiper.esm.js';
Swiper.use([Navigation, EffectFade, Autoplay]);

export default class AccommodationSlider {
    constructor (el) {
        this.slider = el;
        this.sliderOptions = {
            speed: 1200,
            spaceBetween: 0,
            slidesPerView: 'auto',
            centeredSlides: false,
            grabCursor: true,
            navigation: {
                nextEl: '.js-slider-next',
                prevEl: '.js-slider-prev',
            },
            // autoHeight: false,
            // breakpoints: {
            //     600: {
            //         slidesPerView: 1.4,
            //     },
            //     900: {
            //         slidesPerView: 1.6,
            //     },
            //     1100: {
            //         slidesPerView: 2,
            //     },
            //     1200: {  // after 1200px we show custom cursor
            //         slidesPerView: 2,
            //         followFinger: false,
            //         simulateTouch: false,
            //     }
            // },
        };
    }
    init () {
        if (!this.slider || !this.sliderOptions) {
            return;
        }
        new Swiper(this.slider, this.sliderOptions);
    }
}
