import inViewport from "./isVisible";

var parCont = document.querySelectorAll('.js-full-parallax-cont');


// // function checkBounding(elem){
// 	var isInViewport = function (elem) {
// 			var bounding = elem.getBoundingClientRect();
// 			// console.log((bounding.top >= 0));
// 			return (
// 				// bounding.top >= 0 && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
// 				// (bounding.top >= 0) && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
// 				// (bounding.top >= 0) || (bounding.bottom <= 0)
// 				(bounding.top >= 0)
// 			);
// 	};
//
// // }


function castParallax(elem) {
	var opThresh = 350;
	var opFactor = 750;
	window.addEventListener("scroll", function(event){
		var top = this.pageYOffset;
		// var top = elem.offsetTop * 2;
		// console.log(window.pageYOffset, top);
		var layers = elem.querySelectorAll(".parallax");
		var layer, speed, yPos;
		for (var i = 0; i < layers.length; i++) {
			layer = layers[i];
			speed = layer.getAttribute('data-speed');
			var yPos = -(top * speed / 100);
			layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px)');
		}

		// var top = elem.offsetTop * 2;
		// console.log(window.pageYOffset, top);
		//
		// let scrolled = window.pageYOffset / 2;
    // scrolled = elem.pageYOffset;
    // scrolled = window.pageYOffset - elem.offsetTop;
		// let rate = scrolled * 0.5;
		//
		// var bounding = elem.getBoundingClientRect();
		// (bounding.top >= 0 && bounding.bottom <= window.innerHeight) || (bounding.top < window.innerHeight && bounding.bottom >= 0)
		//
		// var layers = elem.querySelectorAll(".parallax");
		// var layer, speed, yPos;
		// for (var i = 0; i < layers.length; i++) {
		// 	layer = layers[i];
		// 	speed = layer.getAttribute('data-speed');
		// 	var yPos = -(top * speed / 100);
		// 	layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px)');
		// }


	});
}



// for (var i = 0; i < parCont.length; i++) {
// 	console.log('casting');
// 	document.body.onload = castParallax(parCont[i]);
// }
// inViewport(parCont[i]);

// document.body.onload = function(){





// function checkVisible(elem){
// 	if (elem.classList.contains('js-visible')) {
// 		return true;
// 	}else{
// 		return false;
// 	}
// }

// run event handler

// for (var i = 0; i < parCont.length; i++) {
// 	inViewport(parCont[i]);
// 	console.log(i);
// 	// console.log(parCont[i]);
// 	window.addEventListener('scroll',  function(parContainer){
// 		// console.log(checkVisible(parCont[i]));
// 		if( checkVisible(parContainer) ){
// 			console.log('VISIBLE!');
// 			castParallax(parContainer);
// 			console.log('in vewport');
// 		}
// 	});
// }


// Get the matching nodes in a node list and convert to an Array
let ary = Array.prototype.slice.call(document.querySelectorAll(".js-full-parallax-cont"));

// Now, you can safely use .forEach()
let j = 0;
// inViewport(ary);
ary.forEach(function(el) {
		castParallax(el);
		// console.log(j);
		// console.log(inViewport(el));
    // Callbacks are passed a reference to the event object that triggered the handler
    window.addEventListener("scroll", function(evt) {
				// console.log(el);
        // The this keyword will refer to the element that was clicked
				// console.log(el.classList.contains('js-visible'));
				if (el.classList.contains('js-visible')) {
					// console.log('VISIBLE!');
					// console.log(el);
					// castParallax(el);
				}

    });
		j++;
})

//
//
//
// $(window).on('resize scroll', function() {
//     if ($('#Something').isInViewport()) {
//         // do something
//     } else {
//         // do something else
//     }
// });
// 8:14
// if ($('#element').visible(true)) {
//     // The element is visible, do something
// } else {
//     // The element is NOT visible, do something else
// }
