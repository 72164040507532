import initSliders from './swiper-main';
import readMore from './readmore';
import initToggleMenu from './components/menuToggle';
import initParallaxElements from './components/parallax';
import watchGalleryImages from "./components/galleries-lightbox";
// import GalleriesSlider from "./components/galleries-slider";
import inViewport from "./components/isVisible";
import animate from "./components/animate";
import animateSvg from "./components/drawSvg";
import initMomentumScrol from "./components/momentum-scrol";
import initjQuery from "./components/jQuery-stuff";
import goBack from "./components/backBtn";
import AnimatedLinksObjs from "./components/distortLink";
import hoverDoesMove from "./components/moveOnHover";

hoverDoesMove();

// [...document.querySelectorAll('.glitch__link, .menu-item a')].forEach((el) => {
[...document.querySelectorAll('.glitch__link')].forEach((el) => {
    const elPosition = [...el.parentNode.children].indexOf(el);
    const fxObj = AnimatedLinksObjs[elPosition];
    fxObj && new fxObj(el);
});

let changeHeader = function(){
  let pageIntroSect = document.querySelectorAll('.js-intro-vis');
  // console.log(pageIntroSect);
  inViewport(pageIntroSect);
}


function toggleBodyScrolledClass () {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const offset = windowHeight * 0.9;
    let ticking = false;
    if (!ticking) {
        window.requestAnimationFrame(() => {
            if (window.scrollY > offset) {
                document.body.classList.add('is-scrolled');
            } else {
                document.body.classList.remove('is-scrolled');
            }
            ticking = false;
        });
    }
    ticking = true;
}
window.addEventListener('scroll', toggleBodyScrolledClass, {passive: true});

function toggleSticky(){
  const headerScrollHandler = () => {
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const offset = windowHeight * 0.2;
  let ticking = false;
  if (!ticking) {
      window.requestAnimationFrame(() => {
          if (window.scrollY > offset) {
              document.body.classList.add('sticky-visible');
          } else {
              document.body.classList.remove('sticky-visible');
          }
          ticking = false;
      });
  }
  ticking = true;
  };
  window.addEventListener('DOMContentLoaded', headerScrollHandler, { passive: true });
  window.addEventListener('scroll', headerScrollHandler, { passive: true });
}
document.addEventListener('DOMContentLoaded', toggleSticky);



document.addEventListener('DOMContentLoaded', () => {
    initToggleMenu();
}, {once: true});


window.addEventListener('load', () => {
    // videoPlayOnLoad();
    // initMomentumScrol();
    initSliders();
    readMore();
    toggleSticky();
    toggleBodyScrolledClass();
    animate();
    // gallerieSlidersInit();
    watchGalleryImages();
    initParallaxElements();
    changeHeader();
    goBack();
    initjQuery();
    animateSvg();
}, {once: true});
