export default function animateSvg() {
  var paths = document.querySelectorAll('#hotel-illustration path');
  var isInViewport = function (elem) {
      var bounding = elem.getBoundingClientRect();
      return (
        (bounding.top >= 0) && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
      );
  };
  var doAnimation = function (elementList) {
    for(let j= 0;j<elementList.length;j++){
      if (isInViewport(elementList[j]) && !(elementList[j].classList.contains('js-do-animation'))) {
        elementList[j].classList.add('js-do-animation');

        paths.forEach(function(item, index){
          var pathLength = item.getTotalLength();
          item.setAttribute("stroke-dasharray", pathLength/1);
          item.setAttribute("stroke-dashoffset", pathLength/1);
          item.innerHTML = "<animate attributeName='stroke-dashoffset' begin='0.6s' dur='10s' to='0' fill='freeze' repeatCount='1' restart='always'/><animate attributeName='fill' from='rgba(180,131,81,0)' to='rgba(180,131,81,0.55)' begin='3.6s' dur='3.5s' fill='freeze' repeatCount='1' restart='always'/>";

          item.querySelectorAll('animate').forEach(element => {
            element.beginElement();
          });
        });
      }
    }
  };


  let acomSect = document.querySelectorAll('.js-hotel-illustration-animate');
  doAnimation(acomSect);



  window.addEventListener('scroll', function(event) {
    doAnimation(acomSect);
  }, false);
}
