export default function animate() {
  var textToAnimate = document.querySelectorAll('.js-animate-text');
  var imagesToAnimate = document.querySelectorAll('.js-animate-fade');
  var sliderToAnimate = document.querySelectorAll('.js-animate-clip');
  var circleToAnimate = document.querySelectorAll('.js-animate-circle');
  var homeSliderToAnimate = document.querySelectorAll('.js-animate-homeslider');
  let moveOnHoverCont = document.querySelectorAll('.background-move');
  let animSect = document.querySelectorAll('.js-section-animate');
  var isInViewport = function (elem) {
      var bounding = elem.getBoundingClientRect();
      return (
        // bounding.top >= 0 && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
        // (bounding.top >= 0) && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
        (bounding.top >= 0 && bounding.bottom <= window.innerHeight) || (bounding.top < window.innerHeight && bounding.bottom >= 0)
      );
  };
  var doAnimation = function (elementList) {
    for(let j= 0;j<elementList.length;j++){
      if (isInViewport(elementList[j]) && !(elementList[j].classList.contains('js-do-animation'))) {
        elementList[j].classList.add('js-do-animation');
      }
    }
  };
  doAnimation(textToAnimate);
  doAnimation(imagesToAnimate);
  doAnimation(sliderToAnimate);
  doAnimation(circleToAnimate);
  doAnimation(homeSliderToAnimate);
  doAnimation(moveOnHoverCont);
  doAnimation(animSect);

  window.addEventListener('scroll', function(event) {
    doAnimation(textToAnimate);
    doAnimation(imagesToAnimate);
    doAnimation(sliderToAnimate);
    doAnimation(circleToAnimate);
    doAnimation(homeSliderToAnimate);
    doAnimation(moveOnHoverCont);
    doAnimation(animSect);
  }, false);
}
