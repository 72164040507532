export default function initjQuery() {

/*Alex Js Experience */

/* roses are red, */
/* violets are blue, */
/* this easter egg, */
/* is just for you <3 */

        if($('body').hasClass('page-template-tmpl_experience')){
            $(".breadcrumb a").click(function(){
                $(".breadcrumb a").removeClass('active');
                $(this).addClass('active');
                var data_cat=$(this).attr("data-cat");
                $('.section-container').removeClass('active');
                // $('.section-concept__tab_cont').removeClass('active');
                $('.section-container').slideUp();
                // $('.section-concept__tab_cont').slideUp();

                $('.page-template-tmpl_experience__beaches').removeClass('active');
                $('.page-template-tmpl_experience__beaches').css('display','none');

                $(".section-container").each(function() {
                        if($(this).attr("data-cat") == data_cat){
                            $(this).addClass('active');
                            $(this).slideDown();
                        }
                 });

                 $(".page-template-tmpl_experience__beaches").each(function() {
                         if($(this).attr("data-cat") == data_cat){
                             $(this).addClass('active');
                             $(this).css('display','block');
                         }
                  });

                return false;
            });

            /*Change background image while svg rotates*/
            $(function () {
                var bg_image = $('.bg-image-seasons');
                var data_image1=$('.bg-image-seasons').attr("data-image1");
                var data_image2=$('.bg-image-seasons').attr("data-image2");
                var data_image3=$('.bg-image-seasons').attr("data-image3");
                var data_image4=$('.bg-image-seasons').attr("data-image4");
                var backgrounds = [
                  'url('+data_image1+')',
                  'url('+data_image2+')',
                  'url('+data_image3+')',
                  'url('+data_image4+')',
                  ];
                var current = 0;

                function nextBackground() {
                    bg_image.css(
                        'background',
                    backgrounds[current = ++current % backgrounds.length]);

                    setTimeout(nextBackground, 2000);
                }
                setTimeout(nextBackground, 2000);
                bg_image.css('background', backgrounds[0]);
            });
        }
}
