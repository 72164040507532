/* global gm */

import loadGoogleMapsAPI from './loadGoogleMapsAPI';
import PropertyMap from './locationMap';
let googleMapsAPIKey;
if(typeof gm !== 'undefined'){
  if (gm && gm.mapApiKey.length > 12) {
     googleMapsAPIKey = gm.mapApiKey;
  } else {
     googleMapsAPIKey = 'AIzaSyCMQFxTvlTmLAfRtYtBe7xN3e-tL-D0kZ0';
   }
}else{
  googleMapsAPIKey = 'AIzaSyCMQFxTvlTmLAfRtYtBe7xN3e-tL-D0kZ0';
}
window.onload = function (){
  const propertyMapContainer = document.querySelector('.gmap-container');
  if (propertyMapContainer) {
     let propertyCoords = propertyMapContainer.dataset.coords;
     let dataZoom = propertyMapContainer.dataset.zoom;
     let propertyZoom = 14;
     if(dataZoom) {
         propertyZoom = dataZoom;
     }
     loadGoogleMapsAPI({key: googleMapsAPIKey}).then(() => {
         new PropertyMap(
             propertyMapContainer,
             propertyCoords,
             propertyZoom,
             propertyCoords,
             );
     }).catch((e) => {
         console.error(e);
     });
  }
}
