const CALLBACK_NAME = '__googleMapsApiOnLoadCallback';

const OPTIONS_KEYS = ['client', 'key', 'language', 'region', 'v'];

module.exports = function (options) {
    options = options || {};

    return new Promise(function (resolve, reject) {
        // Exit if not running inside a browser.
        if (typeof window === 'undefined') {
            return reject(
                new Error('Can only load the Google Maps API in the browser')
                );
        }

        // Reject the promise after a timeout.
        var timeoutId = setTimeout(function () {
            window[CALLBACK_NAME] = function () {}; // Set the on load callback to a no-op.
            reject(new Error('Could not load the Google Maps API'));
        }, options.timeout || 10000);

        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            return resolve(console.log('Google Maps API already loaded'));
        }

        // Hook up the on load callback.
        window[CALLBACK_NAME] = function () {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
            resolve(window.google.maps);
            delete window[CALLBACK_NAME];
        };

        // Prepare the `script` tag to be inserted into the page.
        const scriptElement = document.createElement('script');

        const params = ['callback=' + CALLBACK_NAME];
        OPTIONS_KEYS.forEach(function (key) {
            if (options[key]) {
                params.push(key + '=' + options[key]);
            }
        });
        if (options.libraries && options.libraries.length) {
            params.push('libraries=' + options.libraries.join(','));
        }
        scriptElement.src = 'https://maps.googleapis.com/maps/api/js?' + params.join('&');
        scriptElement.defer = 'defer';
        scriptElement.async = 'async';

        // Insert the `script` tag.
        document.body.appendChild(scriptElement);
    });
};
