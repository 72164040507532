export default function initToggleMenu() {
  var buttons = document.querySelectorAll('.js-menu-toggle');
  var menu = document.querySelector('.site-header__menu');
  var contentDiv = document.querySelector('.site-content');
  var body = document.querySelector('body');
  for (var i = 0; i < buttons.length; i++) {
    var toggler = buttons[i];
    toggler.addEventListener('click', (e) => {
      menu.classList.toggle('js-visible-menu');
      contentDiv.classList.toggle('js-blur');
      body.classList.toggle('js-menu-open');
    });
  }
}
