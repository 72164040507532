export default function inViewport(elem) {
  // let elem = document.querySelectorAll('.js-intro-vis');
  // console.log(elem, 'in module isVisible');

  var isInViewport = function (elem) {
      var bounding = elem.getBoundingClientRect();
      return (
        // bounding.top >= 0 && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
        // (bounding.top >= 0)
        // (bounding.top >= 0) && (bounding.bottom <= window.innerHeight+(bounding.bottom-bounding.top)/2)
        (bounding.top >= 0 && bounding.bottom <= window.innerHeight) || (bounding.top < window.innerHeight && bounding.bottom >= 0)
      );
  };

  // window.addEventListener('scroll', function() {
  // 	var element = document.querySelector('#main-container');
  // 	var position = element.getBoundingClientRect();
  //
  // 	// checking whether fully visible
  // 	if(position.top >= 0 && position.bottom <= window.innerHeight) {
  // 		console.log('Element is fully visible in screen');
  // 	}
  //
  // 	// checking for partial visibility
  // 	if(position.top < window.innerHeight && position.bottom >= 0) {
  // 		console.log('Element is partially visible in screen');
  // 	}
  // });



  var inView = function (elem) {
    let elementList = elem;
    for(let j= 0;j<elementList.length;j++){
      if (isInViewport(elementList[j]) && !(elementList[j].classList.contains('js-do-animation'))) {
        elementList[j].classList.add('js-visible');
        // console.log(elementList[j],'visible');
      }
    }
  };

  window.addEventListener('scroll', function(event) {
    inView(elem);
  }, false);
}
