export default function watchGalleryImages () {
  const watchedElements = Array.prototype.slice.call(document.querySelectorAll('.js-gallery-image'));
  function lazyLoad(img){
    let url = img.getAttribute('data-url');
    img.style.backgroundImage = "url(" + url + ")";
  }
  const clickHandler = (el) => {
    let event = 'click';
    let gallery = el.nextElementSibling;
    if (gallery.querySelector('.swiper-wrapper').innerHTML.trim() !== ""){
      gallery.classList.toggle('js-display-lightbox');
      document.getElementsByTagName('body')[0].classList.toggle('noscroll');
      let elCloser = gallery.querySelector('.js-lightbox-toggler');

      let lazyImages = gallery.querySelectorAll(".js-lazy-image");
      for (var i = 0; i < lazyImages.length; i++) {
        lazyLoad(lazyImages[i]);
      }

      elCloser.addEventListener('click', function(){
        this.parentElement.classList.toggle('js-display-lightbox');
        this.classList.toggle('js-clicked-button');
        document.getElementsByTagName('body')[0].classList.toggle('noscroll');
      }, { once: true });
    }
  }
  for (var i = 0; i < watchedElements.length; i++) {
    let el = watchedElements[i];
    el.addEventListener('click', function(){
      clickHandler(this)
    });
  }
}
